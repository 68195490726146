import React from 'react';
import config from '../../config.js';

const SportIcon = ({ logo, size = 36 }) => {
  return (
    <img
      className="sport-icon"
      src={`${config.cloudfront}${logo}`}
      alt="Sport Logo"
      style={{ width: size, height: size }}
    />
  );
};

export default SportIcon;
