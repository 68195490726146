import React from 'react';
import SportIcon from '../icons/SportIcon.js';

const SearchResult = ({ href, sport, children }) => {
  const backgroundColor = sport.colors?.primary ?? 'white';

  return (
    <a
      className="search-result"
      style={{ backgroundColor }}
      href={href}
    >
      <SportIcon logo={sport.logo} />
      <p className="search-results__text">
        {children}
      </p>
      <span className="search-results__chevron">▶︎️</span>
    </a>
  );
};

export default SearchResult;
