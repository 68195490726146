import * as React from 'react';
import Layout from '../../components/Layout.js';
import { useSports } from '../../stores/Sports/SportsSelectors.js';
import { isLoadingStatus } from '../../stores/Status.js';
import Loading from '../../components/Loading.js';
import SearchResults from '../../components/search/SearchResults.js';
import SearchResult from '../../components/search/SearchResult.js';

const Sports = () => {
  const { sports } = useSports();

  return (
    <SearchResults>
      {sports.map(sport => (
        <SearchResult key={`sport-${sport.id}`} sport={sport} href={`/banners/sports/${sport.id}`}>
          {sport.name}
        </SearchResult>
      ))}
    </SearchResults>
  );
};

const LoadingDelegate = () => {
  const { status } = useSports();
  const isLoading = isLoadingStatus(status);

  return isLoading
    ? <Loading message="Loading Sports..." />
    : <Sports />;
};

const SportsBannersPage = () => {
  return (
    <Layout title={{ black: 'Configure', blue: 'Sport Banners' }}>
      <LoadingDelegate />
    </Layout>
  );
};

export default SportsBannersPage;
